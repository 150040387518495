.richEditor {
  width: 100%;

  .richeditorRoot {
    padding: 1rem;
    border: 1px solid black;
    max-height: 20rem !important;
    min-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .stylingContainer {
    display: flex;
    column-gap: 0.3rem;
  }

  .styleButton {
    color: #999;
    cursor: pointer;
    padding: 0.3em;
    font-size: 1rem;

    svg {
      font-size: 1.5rem;
      margin-top: -0.2rem;
      margin-bottom: -0.4rem;
      color: grey;
    }
  }

  .activeButton {
    color: blue !important;
    background-color: #5890ff22;
    border-radius: 0.5rem;

    svg {
      color: blue;
    }
  }

  .bold {
    font-weight: bolder;
  }

  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
    text-decoration-color: #999;
  }

  .emojiSelect {
    background-color: white;
    border: none;
    font-size: 1.5rem;
    margin-top: -0.2rem;
    color: grey;
  }

  .inputLink {
    margin-top: 0.2rem;
    position: relative;

    div {
      position: absolute;
      background-color: white;
      display: flex;
      vertical-align: middle;
      box-shadow: 0px 0px 2px 2px #1114;
      border-radius: 0.5rem;
      left: calc(50% - 5rem);
      top: calc(50% + 0.5rem);
      input {
        height: 1.5rem;
        width: 10rem;
        border: none;
        margin: 0 0 0 0.5rem;
        &:focus-visible {
          outline: none !important;
          border: none !important;
        }
      }
      svg {
        font-size: 1.2rem;
        align-self: center;
        margin-right: 0.2rem;
        cursor: pointer;
        &.check:hover {
          color:blue;
        }
        &.cancel:hover {
          color: red;
        }
      }
    }
  }
}